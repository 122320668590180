import React, { Component } from 'react'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import BG from "../img/user.png";
import LG from "../img/logo.png";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      Username : '',
    }

    this.forgotPassword = this.forgotPassword.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  async resendConfirmationCode(){
    try {
      await Auth.resendSignUp(this.state.Username);
      // console.log('code resent successfully');
    } catch (err) {
        // console.log('error resending code: ', err);
    }
  }

  handleFormSubmission(evt) {
    evt.preventDefault();
    this.forgotPassword();
  }

  async forgotPassword() {
    const username = this.state.Username;

    try{
      await Auth.forgotPassword(username)
      this.props.SetUserName(username)
      this.props.SetAuthState(AuthState.ResetPassword)
    } catch(err){
      // console.log(err)
    }
  }

  render() {
    return (


      <div className="colorfondo">
        <div className="parent clearfix">
          <div className="bg-illustration">
          </div>

          <div className="login">
            <div className="container">
              <br></br>
              <h2 className="titleOD2"> Cambiar contraseña</h2>

                      <div className="form-group">
                        <input className="form-control text-center" type="text" placeholder="Username" onChange={(e) => this.setState({Username: e.target.value})}></input>
                      </div>

                      
                      <div className="form-group">
                        <a className="form-control btn btn-primary" onClick={this.handleFormSubmission} >Enviar Codigo</a>
                      </div>
              </div>
            </div>
          </div>
        </div>

      
     
    )
  }
}

export default ForgotPassword