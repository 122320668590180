import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import * as tokenn from "../services/token";
import Button from "@mui/material/Button";
// import ReactJsAlert from "reactjs-alert";

import * as acesstokenn from "../services/token";
import LoadingScreen from "react-loading-screen";
import Spinner from "../componentsCFDI/S.gif";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "./validador.css";
import envioPPDF from "./pdf.png";
import envioXML from "./xml.png";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { setTimeout } from "timers";
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

export default function Validador() {
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("success");
  const [cueropoalert, setcueropoalert] = useState("success");

  const [title, setTitle] = useState("This is a alert");
  const [cuentas, setCuentas] = useState([]);

  const [isLoding, setIsLoading] = useState(false);
  const acceso = String(tokenn.tokenn);
  const accesotoken = String(acesstokenn.acesstokenn);
  const [opp9, setOP9] = React.useState("");
  console.log(opp9);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [file2, setFile2] = useState(null);
  const [fileName2, setFileName2] = useState(null);

  const [nFactura, setnFactura] = useState(null);

  const [numSer, setNumSer] = useState();
  const [folioType, setFolioType] = useState("");

  const handleChangee0 = (event) => {
    setNumSer(event.target.value);
    numType();
  };

  function bancodefault() {
    if (cuentas[0]?.default == true) {
      setFolioType(cuentas[0]?.payment_account);
    } else {
      setFolioType(cuentas[1]?.payment_account);
    }
  }

  function numType() {
    var indice2 = cuentas?.findIndex(
      (filio) => filio.payment_method === numSer
    );

    if (indice2 == -1) {
      try {
        setFolioType(cuentas[0]?.payment_account);
      } catch (err) {
        console.log(err);
      }
    } else {
      setFolioType(cuentas[indice2]?.payment_account);
    }
  }

  const handleChangee9 = (event) => {
    setOP9(event.target.value);
  };

  const handleChangeNfactura = (event) => {
    setnFactura(event.target.value);
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const fileTypes = ["PDF"];
  const fileTypes2 = ["XML"];

  const handleChange = (file) => {
    bancodefault();
    fileToBase64(file, (err, result) => {
      if (result) {
        setFile(result);
        setFileName(file);
      }
    });
  };

  const verificarPDFXML = () => {
    if (file == null) {
      alert("No has cargado ningún documento");
    } else {
      numType();
    }
  };

  const handleChange2 = (file) => {
    fileToBase64(file, (err, result) => {
      if (result) {
        setFile2(result);
        setFileName2(file);
      }
    });
  };

  function handleSubmit(event) {
    event?.preventDefault();

    if (opp9.paymentCard == undefined) {
      console.log("Entrando");
    }

    var dato = {
      files: [
        {
          type: "xml",
          content: file2?.replace("data:text/xml;base64,", ""),
        },
        {
          type: "pdf",
          content: file?.replace("data:application/pdf;base64,", ""),
        },
      ],
    };
    console.log(dato);

    let axiosConfig = {
      headers: {
        Authorization: acceso,
        "access-token": accesotoken,
        "Content-Type": "application/json",
      },
    };
    setIsLoading(true);
    axios
      .post(
        "https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/supplier-invoices",
        dato,
        axiosConfig
      )
      .then((res) => {
        alert("Exito, se envio correctamente los documentos");
        window.location = "/validador";
      })

      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        var arrError = err?.response?.data?.errorMessage?.split('.');
        console.log(arrError[0])

        if (arrError[0] === "Error: This invoice already exist!") {
          alert("Error: El docuemnto ya existe");
        } else if (arrError[0] === "Error: Invalid XML, check your files") {
          alert("Error: XML no válido, verifique sus archivos");
        } else if (arrError[0] === "Error: Missing files") {
          alert("Error: Perdida de archivos");
        } else if (arrError[0] === "Validation error: The invoice is not for this company") {
          alert("Error de validación: La factura no es para esta empresa.");
        } else if (arrError[0] === "Validation error: You are temporarily blocked from sending documents") {
          alert("Error de validación: Está bloqueado temporalmente para enviar documentos.");
        } else if (arrError[0] === "Validation error: The invoice total is outside the allowed limit") {
          alert("Error de validación: El total de la factura está fuera del límite permitido.");
        } else if (arrError[0] === "Validation error: The invoice date is outside the allowed time") {
          alert("Error de validación: La fecha de la factura está fuera del tiempo permitido.");
        } else if (arrError[0] === "Validation error: You don't have permission to send documents yet") {
          alert("Todavía no tienes permiso para enviar documentos.");
        } else{
          alert("Error no controlado comuniquese con soporte.");
        }
        

        setIsLoading(false);
      });
  }

  useEffect(() => {
    const handlesumar = () => {
      if (status == true) {
      }
    };
    handlesumar();
  });

  function limpiarycerra() {
    setStatus(false);
    window.location = "/marelli";
  }

  return (
    <div className="containerPDF-XML">
      {isLoding ? (
        <LoadingScreen
          className="LoadinScreen"
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc={Spinner}
          text="Enviando documentos..."
        />
      ) : (
        <div>
          <div className="xml-pdf-container">
            <div className="instrucciones-container ">
              <ul className="Instrucciones">
                <h4>Instrucciones:</h4>
                <li>
                  <p>
                    {" "}
                    1. Arrastre o seleccione el PDF/XML a enviar en su apartado
                    correspondiente.{" "}
                  </p>
                </li>
                <li>
                  2. Haga clic en el botón de enviar para subir su documento.
                </li>
              </ul>
            </div>

            <br></br>
            <br></br>

            <div className="pdf-container">
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                placeholder="123-45-678"
              >
                <div className="drangAndDrop">
                  <img className="validadorDoc" src={envioPPDF}></img>

                  <p>Arrastra o selecciona el PDF a envíar</p>
                </div>
              </FileUploader>
              <div className="upload-area">
                {fileName && <p className="filename">{fileName.name}</p>}
              </div>
            </div>
            <div className="xml-container">
              <FileUploader
                handleChange={handleChange2}
                name="file"
                types={fileTypes2}
                placeholder="123-45-678"
              >
                <div className="drangAndDrop">
                  <img className="validadorDoc" src={envioXML}></img>

                  <p>Arrastra o selecciona el XML a envíar</p>
                </div>
              </FileUploader>
              <div className="upload-area">
                {fileName2 && <p className="filename">{fileName2.name}</p>}
              </div>
            </div>

            <div className="boton-container">
              <Box>
                <Button
                  sx={{ m: 2, width: "20ch" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Enviar Documentos
                </Button>
              </Box>
            </div>
          </div>

          <div>
            {/* {file?.replace("data:application/pdf;base64,", "")}
           {file2?.replace("data:text/xml;base64,", "")} */}
          </div>
        </div>
      )}
    </div>
  );
}
