import React, { Component } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SetEscaner from "../pages/Escaner";
import SetSocioNegocio from "../pages/Respuesta";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SearchBar from "../nav/SearchBar";
import BookData from "../nav/Data.json";
import "../pages/resp.css";
import './HeroSection.css';
import "../Login/style.css";
import "./pedidos.css";
import Inicio from "./Masindormacion";
import SetProveedor from "./proveedor";
import inicoHI from "./fondo.jpg"
import { Dropdown, Menu } from "semantic-ui-react";
import Validador from "../componentsProveedores/validador";
import ConsultarFacturasProvedores from "../componentsProveedores/consultarFacturasProvedores";
import htLogo from '../img/logo.png';
import HelpUser from "../componentsHelpUser/helpUser";

const styles = {
  grid1: {
    width: "90%",
    backgroundColor: "white",
    margin: "0 auto",
  },
};

const Nav = styled.div`
  background: #1a374d;
  height: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;


export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ROLE_BUSINESS_PARTNER: "",
      mostrarComponenteInicio: true,
      mostrarComponente: false,
      mostrarComponenteProvedor: false,
    };
  }
  
  render() {
    
    sessionStorage.setItem("accessToken",this?.props?.User?.["signInUserSession"]?.["accessToken"]?.["jwtToken"]);
    sessionStorage.setItem("idToken",this?.props?.User?.["signInUserSession"]?.["idToken"]?.["jwtToken"]);

    console.log(this?.props?.User?.["signInUserSession"]?.["accessToken"]?.["jwtToken"])

    console.log(this?.props?.User?.["signInUserSession"]?.["idToken"]?.["jwtToken"])
    console.log(this?.props?.User?.["attributes"]?.["custom:roles"]);
    var permisosUsuario = this?.props?.User?.["attributes"]?.["custom:roles"];
    var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
    var permisos = permisoUsuarioReplace?.split(",");
     console.log(permisos)
    if (this.props.AuthState === AuthState.SignedIn) {
      const role_user = "ROLE_BUYS";
      var permisoUsuario = permisos?.filter((x) => x == role_user);
      var ROLE_BUYS = permisoUsuario?.length ? " " : "none";
      return (
        <div className="tc">
          {this.props.User === null ? (
            <div> Loading User </div>
          ) : (
            <div className="tc w-100">
              <Router>
              <Menu>
                  <Menu.Item>
                    <a href="https://ht-proveedores.herramientasinformaticas.com/"><img className="logo-img" src={htLogo} alt="logo" /></a>
                  </Menu.Item>
                  <Dropdown
                    text={<span className="material-symbols-outlined nColor">menu</span>}
                    pointing
                    className="link item"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Dropdown.Item>
                      <Dropdown.Item href="/validador"><a className="encabezadoA nColor" href="/validador"> ENVÍAR DOCUMENTOS</a></Dropdown.Item>
                      <Dropdown.Item href="/consultaFacturasProvedores"><a className="encabezadoA nColor" href="/consultaFacturasProvedores"> CONSULTAR DOCUMENTOS ENVÍADOS</a></Dropdown.Item>
                      <Dropdown.Item><a className="encabezadoA nColor" href="/helpVideo">AYUDA</a></Dropdown.Item>

                    </Dropdown.Menu>
                  </Dropdown>
                  <Menu.Item>
                    <SearchBar
                      placeholder="Busqueda personalizada"
                      data={[]}
                    />
                  </Menu.Item>
                  <Menu.Item></Menu.Item>
                  <Menu.Item></Menu.Item>
                  <Dropdown
                    text={
                      <span className="nColor">
                        {this.props.User["attributes"]["email"]}
                      </span>
                    }
                    pointing
                    className="link item"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item><a className="encabezadoA nColor"> CONFIGURACIÓN</a></Dropdown.Item>
                      <Dropdown.Item><a className="encabezadoA nColor"> CUENTA</a></Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Divider />
                      <Dropdown.Item><a className="encabezadoA nColor" onClick={(e) => this.props.SetAuthState(AuthState.SignedOut)}> CERRAR SESIÓN</a></Dropdown.Item>
                      
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu>

                <Switch>
                  <Route
                    path="/Validador"
                    exact
                    component={Validador}
                  />
                   <Route
                    path="/consultaFacturasProvedores"
                    exact
                    component={ConsultarFacturasProvedores}
                  />

                  <Route path="/helpVideo" exact component={HelpUser}/>

                
                </Switch>
                <h4>v1.7.0</h4>
              </Router>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div className={this.state.mostrarComponenteInicio ? "show-element" : null}>
            {this.state.mostrarComponenteInicio && (
          <div className='hero-container'>
      {/* <video src="../.mp4" autoPlay loop muted /> */}
      <img className='imgInicioFOndo' src={inicoHI}></img> 
      <h1>HOTEL TRUEBA</h1>
      <p>Ingresa tus facturas </p>
      <p>(Comprobantes CFDI) </p>

      <div className='hero-btns'>
        <button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={(e) => this.setState({ mostrarComponente: true, mostrarComponenteInicio:false,mostrarComponenteProvedor:false })}

        >Más información
                  <i className='far fa-play-circle' />

        </button>
       
        <button
          className='btns-contac'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={(e) => this.props.SetAuthState(AuthState.SignIn)}

          >Proveedores
        </button>
      </div>

        </div>
           )}
           </div>
        <div className={this.state.mostrarComponente ? "show-element" : null}>
            {this.state.mostrarComponente && (
              <div>
                <button
                  className='btns entodo'
                  buttonStyle='btn--outline'
                  buttonSize='btn--large'
                  onClick={(e) => this.setState({ mostrarComponente: false, mostrarComponenteInicio:true,mostrarComponenteProvedor:false })}

                >Regresar al inicio
                </button>
                <div className="">
                  <Inicio></Inicio>

                </div>
              
              </div>
            )}
          </div>

          <div className={this.state.mostrarComponenteProvedor ? "show-element" : null}>
            {this.state.mostrarComponenteProvedor && (
              <div>
                <button
                  className='btns entodo'
                  buttonStyle='btn--outline'
                  buttonSize='btn--large'
                  onClick={(e) => this.setState({ mostrarComponente: false, mostrarComponenteInicio:true ,mostrarComponenteProvedor:false})}

                >Regresar al inicio
                </button>
                <div className="">
                  <SetProveedor></SetProveedor>
                </div>
              
              </div>
            )}
          </div>
        </div>

      );
    }
  }
}

export default index;
