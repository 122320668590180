import "./OperationD.css";
import "./Services.css";
import pic01 from "./pic01.jpg";
import pic03 from "./pic03.jpg";

import React, { useEffect, useState } from "react";
import ReactGoogleSlides from "react-google-slides";
import { Embed,Grid } from "semantic-ui-react";
import ReactPlayer from 'react-player/lazy'
function Inicio() {
  return (
    <div>
      <section id="somos-proya">
        <div className="container">
          <div className="img-container">
          <ReactPlayer
          className='react-player'
          url="https://hisoporte.s3.amazonaws.com/videos/Registrar+usuario.mp4"
          width='100%'
          height='100%'
          controls = {true}
        />
          </div>

          <div className="texto">
            <h3>¿Qué es este sitio?</h3>
            <p>
              Este sitio es una herramienta para automatizar el envío de sus
              facturas (comprobantes CFDI). Como proveedor del Hotel Trueba
              usted puede enviar sus documentos desde esta página.{" "}
            </p>

            <h3>¿Por qué debo enviar mis facturas por este medio?</h3>

            <p>
              Dentro de los procesos de mejora continua de la empresa, ha optado
              por automatizar la recepción de facturas.
            </p>
            <h3>¿Qué debo hacer para enviar mis documentos?</h3>

            <p>
              Primero debe registrarse para poder obtener un nombre de usuario y
              contraseña (haga clic en el botón registro). Después recibirá un
              correo electrónico en donde se le avisará que su cuenta ya está
              disponible y los datos de su cuenta. En la sección de
              procedimiento se le indica paso a paso lo que debe hacer para
              enviar sus documentos.
            </p>
          </div>
        </div>
      </section>

      <section className="knowledge">
        <div className="knowledge__container container">
          <figure className="knowledge__picture">
            <img src={pic01} className="knowledge__img" alt="soporte" />
          </figure>
          <div className="knowledege__texts">
            <h2 className="subtitle">
              Procedimiento para enviar sus facturas.
            </h2>
            <ul>
            <li >No olvide:</li>
            <li>
              1. Ingresar sus archivos xml y pdf
            </li>
            <li>
              2. El nombre de sus archivos antes de la extensión (.xml, .pdf), debe ser el mismo. Por ejemplo factura.xml y factura.pdf.
            </li>
            <li >
              3. Si tiene dudas puede comunicarse con nosotros.
            </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="knowledge">
        <div className="knowledge__container container">
          <div className="knowledege__texts">
            <h2 className="subtitle">Ingresar sus documentos</h2>
            <p className="knowledge__paragraph">
            En el área de envío, existe un apartado para agregar cada uno de sus archivos. 
            Agréguelos y haga clic en enviar. Una vez que el proceso termine se le informará el resultado de su recepción.
            </p>
          </div>
          <figure className="knowledge__picture">
            <img src={pic03} className="knowledge__img" alt="soporte" />
          </figure>
        </div>
      </section>
      <section className="knowledge">
        <div className="knowledge__container container">
          <figure className="knowledge__picture">
            <img src={pic01} className="knowledge__img" alt="soporte" />
          </figure>
          <div className="knowledege__texts">
            <h2 className="subtitle">Datos de facturación</h2>
            <p className="knowledge__paragraph">
            Le agradeceremos que genere sus facturas con los datos que a continuación se presenta: 
            <ul>            
                <li>RAZON SOCIAL: TRUEBA HOTELERA</li>
                <li>RFC: THO771114NZ1</li>
                <li>RÉGIMEN FISCAL: 626</li>
                <li>CALLE: ORIENTE 6</li>
                <li>NÚMERO EXTERIOR: 485</li>
                <li>MUNICIPIO: ORIZABA</li>
                <li>ESTADO: VERACRUZ</li>
                <li>PAÍS: MEXICO</li>
                <li>CP: 94300</li>


            </ul>


            </p>
          </div>
        </div>
      </section>
     
    </div>
  );
}

export default Inicio;
